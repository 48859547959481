import React from 'react'
import styled, { keyframes } from 'styled-components'
import Container from '../Container'
import { ICompany } from '../../typescript/interfaces'
import { fluidOrSvgImg } from '../../utils/fluidOrSvgImg'

type ScrollDirection = 'left' | 'right'

interface Props {
  title?: string
  list: ICompany[]
  transparent?: boolean
  scrollDirection?: ScrollDirection
}

interface MarqueProps {
  company: ICompany[]
  i?: number
}

export default React.memo(({ title, list, transparent, scrollDirection = 'left' }: Props) => {
  const MemoTrack = React.memo(() => {
    const Marquee = ({ company, i }: MarqueProps) => (
      <>
        {Array.from({ length: 2 }, (_, index) => (
          <Ul
            key={index}
            scrollDirection={i ? (scrollDirection[i] as ScrollDirection) : (scrollDirection as ScrollDirection)}
          >
            {company.length &&
              company.map(x => {
                const { imgSrc, alt } = x
                return (
                  <Li key={x?.id}>
                    <a href={x?.url} target='_blank' rel='noreferrer'>
                      <Inner>{imgSrc && fluidOrSvgImg({ imgSrc, alt })}</Inner>
                    </a>
                  </Li>
                )
              })}
          </Ul>
        ))}
      </>
    )

    return <Marquee company={list} />
  })

  return (
    <Marquee transparent={transparent}>
      <Container>
        {title && <Title>{title}</Title>}
        <Track transparent={transparent}>
          <MemoTrack />
        </Track>
      </Container>
    </Marquee>
  )
})

const Inner = styled.div`
  transition: all 0.5s ease;
  padding: 1.5rem 3rem;
  min-width: 15rem;
  width: 15rem;
  border-radius: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    min-width: 10rem;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grey200};
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
`

const Title = styled.p`
  font-weight: 500;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.grey500};
  margin: 0 auto;
  text-align: center;
  padding: 0 1.5rem 3rem;
`

interface MarqueeProps {
  transparent?: boolean
}

const Marquee = styled.article<MarqueeProps>`
  background: ${({ transparent }) => (transparent ? 'transparent' : '#fff')};
`

const Track = styled.div<MarqueeProps>`
  padding: 0 1.5rem;
  overflow: hidden;
  display: flex;
  position: relative;
  ul:nth-of-type(2) {
    transform: translateX(100%);
  }
  &:after {
    content: '';
    pointer-events: none;
    background: linear-gradient(
      to right,
      #fff 0,
      rgba(255, 255, 255, 0.1) 10%,
      rgba(255, 255, 255, 0.1) 90%,
      #fff 100%
    );
    background: ${({ transparent, theme }) =>
      transparent
        ? `linear-gradient(to right, ${theme.colors.grey200} 0, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.1) 90%, ${theme.colors.grey200} 100%)`
        : `linear-gradient(to right, #fff 0, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.1) 90%, #fff 100%)`};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    padding: 0;
  }
`

const MarqueeAnim = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
`

interface UlProps {
  scrollDirection: 'left' | 'right'
}

const Ul = styled.ul<UlProps>`
  display: flex;
  align-items: center;
  animation: ${MarqueeAnim} 60s linear infinite;
  will-change: transform;
  animation-direction: ${({ scrollDirection }) => (scrollDirection === 'left' ? 'normal' : 'reverse')};
`

const Li = styled.li`
  a {
    width: auto;
    cursor: pointer;
  }
`
