import { Link, PageProps } from 'gatsby'
import Img, { GatsbyImageProps } from 'gatsby-image'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { isSafari } from 'react-device-detect'
import { Check, Pause, PlayCircle, Volume2, VolumeX, XCircle } from 'react-feather'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import { SEO } from '../SEO'
import { Button } from '../components/Button'
import Container from '../components/Container'
import Footer from '../components/Footer/Footer'
import Form from '../components/Form'
import Intro from '../components/Intro'
import Marquee from '../components/Marquee'
import Points from '../components/Points'
import { Site } from '../components/Site'
import { Main } from '../components/Styled'
import TestimonialCarousel, { Testimonial } from '../components/TestimonialCarousel'
import { customers, partners } from '../constants'
import { points as keypoints } from '../constants/points'
import { useDiscoveryAuthors } from '../hooks/useDiscoveryAuthors'
import { useDiscoveryAwards } from '../hooks/useDiscoveryAwards'
import { useDiscoveryCardImages } from '../hooks/useDiscoveryCards'
import { useDiscoveryLogos } from '../hooks/useDiscoveryLogos'
import { useExplainerVideo } from '../hooks/useExplainerVideo'
import { useProducts } from '../hooks/useProducts'

const testimonials: Omit<Testimonial, 'imgSrc' | 'authorSrc'>[] = [
  {
    id: 'myob',
    author: 'Steve Targus',
    quote:
      'daisee has helped us really work on a consistent MYOB experience. We use the solution to upskill people to respond better to queries and go beyond. The results were a 22% increase in CSAT scores.',
    role: 'Contact Centre Operations Manager, MYOB'
  }
]

const cards = [
  {
    title: '100% Call Coverage',
    copy: 'daisee augments human intelligence by automatically revealing compliance gaps, caller sentiment, and the underlying reasons why customers are contacting your business.'
  },
  {
    title: 'Enhance Agent Productivity',
    copy: 'Our solution enables agents to view their personal performance and call activity over time. QA managers also have the ability to compare agent performance across multiple teams.'
  },
  {
    title: 'Surface CX Insights',
    copy: 'daisee rapidly transcribes and analyses voice interactions with your customers so that you can turn the rich unstructured emotional and behavioural data of each call into business-improving intelligence.'
  },
  {
    title: 'Increase Revenue & Results',
    copy: "Our technology removes biases, time and human-resources from manually sampling calls. Take action with confidence and use the power of daisee's AI to help narrow your business’s focus."
  }
]

const productPoints = [
  'AI analysis and evaluation of 100% of calls',
  'Team View',
  'Agent View',
  'Call Essence - uncover the reason behind each call',
  'Automated Digital Scorecard™',
  'Script Adherence',
  'Flag calls for exclusion',
  'Excessive Silences filtering',
  'Detection of overtalk, silences and customer sentiment',
  'Compare agent performance across teams',
  'Complete workflow suite for agent coaching',
  'Ability to export data to CSV',
  'Comprehensive call review tooling',
  'Identify high-risk calls and agents'
]

type IntroProps = {
  trackEvent: () => void
  theme: DefaultTheme
}

const IntroContent: React.FC<IntroProps> = ({ trackEvent, theme }) => {
  const hasClickedPlay = useRef(false)
  const { controls, video, state } = useExplainerVideo()

  const handlePlay = () => {
    if (!hasClickedPlay.current) {
      trackEvent()
      hasClickedPlay.current = true
      controls.play()
      return
    }
    controls.play()
  }

  return (
    <Intro bgColor='purple100'>
      <Container>
        <PageContent>
          <Grid>
            <div className='copy'>
              <h1>daisee's AI gives your contact centre the competitive advantage</h1>
              <h2>
                The daisee platform empowers contact centers by leveraging AI to enhance, analyse and evaluate 100% of
                customer conversations. Our solution provides{' '}
                <strong style={{ color: theme.colors.purple }}>comprehensive insights</strong> into the performance of
                your team so that your business can focus on driving revenue, results and a flawless customer
                experience.
              </h2>
              <Button
                disabled={state.playing}
                onClick={handlePlay}
                bgColor={theme.colors.green}
                action='Discover How'
                btnHeight='auto'
              />
            </div>
            <VideoWrapper onClick={handlePlay}>
              {video}
              <SvgWrapper style={{ display: state.playing ? 'none' : 'block' }}>
                <PlayCircle color={theme.colors.green} size={56} strokeWidth={1} />
              </SvgWrapper>
            </VideoWrapper>
          </Grid>
        </PageContent>
      </Container>
    </Intro>
  )
}

type LightBoxProps = {
  shouldAutoplay: boolean
  isFullScreen: boolean
  setIsFullScreen: (arg: boolean) => void
}

const LightBoxContent: React.FC<LightBoxProps> = ({ shouldAutoplay, isFullScreen, setIsFullScreen }) => {
  if (!isFullScreen) {
    return null
  }

  const {
    controls: lightBoxControls,
    video: lightBoxVideo,
    state: lightBoxState
  } = useExplainerVideo({ controls: !shouldAutoplay, autoPlay: shouldAutoplay, muted: shouldAutoplay })

  return (
    <Lightbox isFullScreen={isFullScreen}>
      <div className='controls'>
        {lightBoxState.playing ? (
          <Pause size={32} strokeWidth={1} onClick={() => lightBoxControls.pause()} />
        ) : (
          <PlayCircle size={32} strokeWidth={1} onClick={() => lightBoxControls.play()} />
        )}
        {lightBoxState.muted ? (
          <VolumeX size={32} strokeWidth={1} onClick={() => lightBoxControls.unmute()} />
        ) : (
          <Volume2 size={32} strokeWidth={1} onClick={() => lightBoxControls.mute()} />
        )}
        <XCircle size={32} strokeWidth={1} onClick={() => setIsFullScreen(false)} />
      </div>
      <VideoWrapper onClick={() => lightBoxControls.play()} className='lightbox'>
        {lightBoxVideo}
      </VideoWrapper>
    </Lightbox>
  )
}

const Discover: React.FC<PageProps> = props => {
  const shouldAutoplay = props.location.hash === '#play'
  const [isFullScreen, setIsFullScreen] = useState(shouldAutoplay)
  const theme = useTheme()

  const customersAndPartners = customers
    .filter(logo => logo.id !== 'zip')
    .concat(partners.filter(logo => logo.id !== 'microsoft'))
  const { logos } = useDiscoveryLogos()
  const productImages = useProducts()
  const { authors } = useDiscoveryAuthors()
  const { awards } = useDiscoveryAwards()
  const { cardImages } = useDiscoveryCardImages()
  const trackEvent = () =>
    trackCustomEvent({
      category: 'Explainer Video',
      action: 'Play',
      label: 'Discovery Page - daisee.com/discovery'
    })

  const testimonialsWithImages: Testimonial[] = useMemo(
    () =>
      testimonials.map(({ id, ...rest }) => ({
        ...rest,
        id,
        imgSrc: logos[id]?.childImageSharp?.fluid ?? logos[id].publicURL,
        authorSrc: authors[id]?.childImageSharp?.fluid
      })),
    [logos, authors]
  )

  const cardsWithImages = useMemo(
    () => cards.map((card, i) => ({ ...card, imgSrc: cardImages[i].node.childImageSharp.fluid })),
    [cardImages.length]
  )

  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = 'hidden'
      trackEvent()
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isFullScreen])

  return (
    <Site seo={{ ...SEO.explainer, image: cardImages[0].node.publicURL }} {...props}>
      <Main>
        <IntroContent trackEvent={trackEvent} theme={theme} />
        <Container>
          <PageContent>
            <Marquee title='Trusted By Our Customers and Partners' list={customersAndPartners} />
            <Awards>
              <Img style={{ width: '160px', margin: '1.5rem' }} fluid={awards.anziif.childImageSharp.fluid} />
              <Img
                style={{ width: '160px', margin: '1.5rem' }}
                imgStyle={{ width: '134px', objectFit: 'contain' }}
                fluid={awards.insurance.childImageSharp.fluid}
              />
            </Awards>
            <Section>
              <h3>
                Unleash the full potential of <br />
                your contact centre
              </h3>
              <Grid>
                {cardsWithImages?.map(card => (
                  <div className='card' key={card.title}>
                    <div className='wrapper'>
                      <Img style={{ height: '15rem' }} imgStyle={{ objectFit: 'cover' }} fluid={card.imgSrc} />
                    </div>
                    <div className='copy'>
                      <h4>{card.title}</h4>
                      <p>{card.copy}</p>
                    </div>
                  </div>
                ))}
              </Grid>
            </Section>
          </PageContent>
        </Container>
        <PageContent>
          <div className='cta'>
            <Container>
              <Section>
                <h3>Contact us to discover how you can use AI to transform your contact centre today</h3>
                <div className='points'>
                  <Points points={keypoints} quantity={3} isGreen />
                </div>
                <Form
                  formName='start-a-demo'
                  successMessage='Thank you, we will be in touch with you soon'
                  errorMessage='Oops, something went wrong - please try again'
                  action='Contact'
                  isGtmForm
                  whitefields
                />
              </Section>
            </Container>
          </div>
        </PageContent>
        <PageContent>
          <Container>
            <Section>
              <TestimonialCarousel testimonials={testimonialsWithImages} />
            </Section>
            <Section>
              <h3>Unparalleled performance</h3>
              <Grid>
                <p>
                  <strong className='sub-copy'>
                    Power smarter business decisions and reveal critical insights into your customers’ voice with the
                    industry’s most sophisticated AI-driven voice analytics platform.
                  </strong>
                </p>
              </Grid>
              <PointGrid>
                <div>
                  {productPoints.slice(0, 7).map(point => (
                    <Point key={point}>{point}</Point>
                  ))}
                </div>
                <div>
                  {productPoints.slice(-7).map(point => (
                    <Point key={point}>{point}</Point>
                  ))}
                </div>
              </PointGrid>
              <Grid>
                {isSafari ? (
                  <Img fluid={productImages.qaScore.childImageSharp.fluid} alt='daisee Agent Performance Meeting' />
                ) : (
                  <AnimationWrapper>
                    <video
                      preload='none'
                      autoPlay
                      muted
                      loop
                      playsInline
                      poster='https://res.cloudinary.com/harrybu/video/upload/v1603628923/Scorecard2_picqab.png'
                    >
                      <source
                        src='https://res.cloudinary.com/harrybu/video/upload/v1603628923/Scorecard2_picqab.webm'
                        type='video/webm'
                      />
                      Your browser does not support the video tag.
                    </video>
                  </AnimationWrapper>
                )}
                <StyledImg fluid={productImages.ui3.childImageSharp.fluid} alt='daisee Software' />
              </Grid>
            </Section>
          </Container>
          <div className='cta'>
            <Container>
              <Section>
                <h3>Learn more about our platform</h3>
                <Link to='/product'>
                  <Button
                    style={{ margin: '0 auto' }}
                    bgColor={theme.colors.green}
                    btnHeight='auto'
                    action='Learn More'
                  />
                </Link>
              </Section>
            </Container>
          </div>
        </PageContent>
      </Main>
      <Footer />
      <LightBoxContent isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} shouldAutoplay={shouldAutoplay} />
    </Site>
  )
}

const Point: React.FC = ({ children }) => {
  return (
    <PointWrapper>
      <Check size={16} />
      {children}
    </PointWrapper>
  )
}

const Awards = styled.div`
  display: grid;
  justify-content: center;
  padding-bottom: 1.5rem;
  grid-auto-flow: column;
`

const PointGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem 0;

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr;
  }
`

const PointWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  width: fit-content;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey700};

  svg {
    color: ${({ theme }) => theme.colors.blue};
  }
`

const Lightbox = styled.div<{ isFullScreen: boolean }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ isFullScreen }) => (isFullScreen ? 'visible' : 'hidden')};

  .lightbox {
    padding: 1.5rem;
    height: 100%;
    max-width: 1155px;
    margin: 3rem;
  }

  .controls {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.75rem;
    display: grid;
    gap: 1.4rem;
    grid-auto-flow: column;
    z-index: 900;

    ${({ theme }) => theme.breakpoints.tablet} {
      margin: 2.25rem;
    }

    svg {
      cursor: pointer;
      color: #fff;
      transition: color 0.25s ease;

      &:hover {
        color: ${({ theme }) => theme.colors.green};
      }
    }
  }
`

export const PageContent = styled.div`
  h1,
  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 2.25rem;
    letter-spacing: -1.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
  }

  h2 {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5;
  }

  .copy {
    display: grid;
    gap: 1.5rem;
    height: fit-content;

    button {
      width: fit-content;
    }
  }

  article p {
    padding: 0;
  }

  .cta {
    background: ${({ theme }) => theme.colors.grey200};
    border-top: 1px solid ${({ theme }) => theme.colors.grey300};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};

    > div {
      max-width: 700px;
      display: flex;
      justify-content: center;
    }

    h3 {
      text-align: center;
      max-width: 30ch;
      margin: 0 auto;
    }
  }

  .points {
    display: grid;
    gap: 1rem;
    padding-bottom: 1.5rem;
    justify-content: center;
  }

  .sub-copy {
    font-weight: 500;
    font-size: 1.125rem;
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    h3 {
      font-size: 1.75rem;
    }
  }
`

const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr;
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const VideoWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-bottom: 56.25%;
  width: 100%;
  cursor: pointer;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const AnimationWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledImg = styled(Img)<GatsbyImageProps>`
  box-shadow: ${({ theme }) => theme.boxShadow.large};
`

const Section = styled.section`
  padding: 3rem 0;

  h3 {
    padding-bottom: 3rem;
  }

  .card {
    box-shadow: ${({ theme }) => theme.boxShadow.small};
    border-radius: 1.5rem;
    display: grid;
    overflow: hidden;
    gap: 1.5rem;

    .copy {
      padding: 0 1.5rem 1.5rem;
    }

    h4 {
      margin: 0;
      font-weight: 500;
      font-size: 1.75rem;
      letter-spacing: -1px;
      line-height: 1.2;
      transition: color 0.25s ease;
      color: ${({ theme }) => theme.colors.grey500};
    }

    &:hover {
      h4 {
        color: ${({ theme }) => theme.colors.purple};
      }
    }

    ${({ theme }) => theme.breakpoints.mobile} {
      h4 {
        font-size: 1.5rem;
      }
    }
  }
`

export default memo(Discover)
